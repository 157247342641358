@keyframes slideIn {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes slideOut {
  0% {
    transform: translateX(0px);
    opacity: 1;
  }

  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}

.slideIn {
  animation: slideIn 0.25s ease-in-out both;
}

.slideOut {
  animation: slideOut 0.25s ease-in-out both;
}
